.accordion {
  margin: 0 auto;
  width: 900px;
}
.accordion-body {
  color: #333;
}
.accordion-button.collapsed {
  background-color: #FFFFFF;
  box-shadow: inset 4px 0 0 #3423a6;
}
.accordion-button:not(.collapsed) {
  background-color: #3423a6 !important;
  color: #FFFFFF !important;
}
.accordion-button:hover {
  background-color: #3423a6;
  color: #FFFFFF !important;
}
.author-row {
  padding: 0.75rem 0;
}
.alt-row {
  background: #f7f7f7;
}
.author-name {
  font-weight: bold;
}
