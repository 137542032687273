.paper-info {
  font-size: 14px;
  color: #4b7d92;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.paper-author {
  font-size: 13px;
  color: #000000;
  font-family: 'Noto Serif', serif;
}
.page-number {
  font-size: 13px;
  color: #424040;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}
.download-button .btn {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.5rem;
}
.download-button .btn-secondary {
  --bs-btn-bg: #4b7d92;
}
