.navbar {
  border-top: 5px solid #3423a6;
  border-bottom: 1px solid #3423a6;
  --bs-navbar-padding-x: 0 !important;
  --bs-navbar-padding-y: 0 !important;
  --bs-navbar-nav-link-padding-x: 0.25rem !important;
}
.navbar .navbar-nav {
  margin: 0 auto;
}
.navbar .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-size: 0.93rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  border-bottom-width: 4px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  line-height: 0;
  padding-top: 19px;
  padding-bottom: 19px;
  margin: 0 0.67rem;
}
.navbar .navbar-nav .nav-link:hover {
  border-bottom-color: #3423a6;
}
.navbar .navbar-nav .dropdown {
  border-bottom-width: 4px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  margin-top: 3px;
}
.navbar .navbar-nav .dropdown .dropdown-menu {
  --bs-dropdown-link-active-bg: transparent;
}
.navbar .navbar-nav .dropdown .dropdown-item {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-size: 0.93rem;
  text-transform: uppercase;
  padding-right: 0.67rem !important;
  padding-left: 1.33rem !important;
  font-family: 'Montserrat', sans-serif;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-link-hover-bg: #3423a6;
}
.navbar .navbar-nav .dropdown .dropdown:hover {
  border-bottom-color: #3423a6;
}
.navbar .navbar-nav .dropdown .nav-link:hover {
  border-bottom-color: transparent;
}
.navbar .navbar-nav .dropdown .dropdown-item:hover {
  color: #FFFFFF;
  background: #3423a6;
}
.navbar .btn {
  --bs-btn-border-radius: 2rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.5rem;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1rem;
}
.navbar .btn-primary {
  --bs-btn-bg: #3423a6;
}
.navbar .form-control:focus {
  border-color: #3423a6;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
