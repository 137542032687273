.footer-top {
  background: #3423a6;
  border: none;
  clip-path: ellipse(75% 100% at center top);
  height: auto;
  min-height: 370px;
  padding-bottom: 5rem;
  padding-top: 5rem;
  text-align: center;
}
.footer-heading {
  color: #FFFFFF;
  font-size: 3.125rem;
  font-weight: 800;
  padding: 2rem;
}
.footer-bottom {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.footer-bottom h4 {
  font-weight: bold;
  padding-bottom: 0.5rem;
}
.footer-bottom a {
  color: #454245;
  text-decoration: none;
  font-size: 14px;
}
.footer-text {
  background: #CAF0F8;
  min-height: 150px;
  font-size: 16px;
  color: #333333;
  padding: 1.5rem 0;
  line-height: 2;
}
.footer-text p {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
