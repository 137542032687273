.scope-list li {
  margin-bottom: 0;
  padding: 0.5rem 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
.scope-list p:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.05);
}
.scope-list p.odd {
  background: #eeeeee;
}
