html,
body {
  font-family: "Noto Serif", serif;
  background: #dddddd;
  font-size: 16px;
  line-height: 1.43rem;
  color: rgba(0, 0, 0, 0.87);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}
p {
  text-align: justify;
}
#root {
  background: #dddddd;
  margin: 0 auto;
}
.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
}
.App {
  background: #ffffff;
  max-width: 1160px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.row {
  padding-left: 30px;
  padding-right: 30px;
}
.section-heading {
  color: #3423a6;
  padding: 1.5rem 0 0.75rem;
  line-height: 2;
  font-family: "Montserrat", sans-serif;
  font-size: 1.285rem;
  font-weight: 700;
}
.sub-heading {
  color: #3423a6;
  padding: 1.5rem 0 0;
  line-height: 2;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 700;
}
.heading-line {
  border: 2px solid #d14c09 !important;
  border-bottom: none;
  opacity: 1;
  text-align: left;
  width: 60px;
  margin: 0 25px 25px 0;
}
@media (min-width: 1200px) {
  .App {
    margin-top: 2.143rem;
    margin-bottom: 2.143rem;
  }
}
