.page-logo {
  width: 9rem;
}
.header-row {
  display: flex;
}
.logo-info {
  color: #3423a6;
}
.logo-info p {
  font-family: 'Noto Sans', sans-serif;
}
.login-buttons .btn {
  --bs-btn-border-radius: 2rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.5rem;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.5rem;
}
.login-buttons .btn-primary {
  --bs-btn-bg: #3423a6;
}
